import React from 'react'
import { injectIntl, intlShape, FormattedHTMLMessage } from 'react-intl'
import { compose } from 'recompose'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import Typed from 'typed.js'
// import AOS from 'aos'
// import 'aos/dist/aos.css'

import Link from '../components/LocalizedLink'
import SEO from '../components/seo'

import withPageContext from '../pageContext'
import withLayout from '../layout'

import Products from '../components/Products'

import '../styles/home.sass'

export const query = graphql`
    query {
        automat: file(relativePath: { eq: "home/automat.png" }) {
            childImageSharp {
                fixed(width: 96, height: 96) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        servis: file(relativePath: { eq: "home/servis.png" }) {
            childImageSharp {
                fixed(width: 96, height: 96) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        design: file(relativePath: { eq: "home/design.png" }) {
            childImageSharp {
                fixed(width: 96, height: 96) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
`

// const IndexPage = ({ intl, data }) => (
class IndexPage extends React.Component {
    constructor(props) {
        super(props)
        // AOS.init({
        //     once: true,
        //     duration: 700,
        //     easing: 'ease-out-quad',
        // })
    }

    // componentDidUpdate() {
    //     this.aos.refresh()
    // }

    componentDidMount() {
        const isBrowser = typeof window !== 'undefined'
        // const AOS = isBrowser ? require('aos') : undefined

        // this.aos = AOS
        // this.aos.init({ once: true, duration: 700, easing: 'ease-out-quad' })

        var typed = new Typed('#typed', {
            stringsElement: '#typed-strings',
            typeSpeed: 40,
            backSpeed: 20,
            backDelay: 1000,
            startDelay: 100,
            loop: true,
        })
    }
    render() {
        const { intl } = this.props;
        return (
            <React.Fragment>
                <SEO
                    title=""
                />
                <main>
                    <section id="home" className="parent">
                        <div className="container child">
                            <div className="text-center ">
                                <h1 className="text-center  mb-6 mb-lg-8 mb-5">
                                    <FormattedHTMLMessage id="home.header.title" />
                                </h1>
                                <div id="typed-strings">
                                    <p>
                                        <FormattedHTMLMessage id="home.header.item1" />
                                    </p>
                                    <p>
                                        <FormattedHTMLMessage id="home.header.item2" />
                                    </p>
                                    <p>
                                        <FormattedHTMLMessage id="home.header.item3" />
                                    </p>
                                </div>
                                <h3 className="text-center mb-5">
                                    <FormattedHTMLMessage id="home.header.items.head" />{' '}
                                    <span id="typed" className="text-red"></span>
                                </h3>
                                <div className="text-center ">
                                    <Link
                                        to="/#about"
                                        className="btn btn-custom btn-custom-red"
                                        activeClassName="active"
                                    >
                                        <FormattedHTMLMessage id="global.findmore" />
                                        {/* <FormattedMessage id="home.about.title" /> */}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="home-items" className="font-bigger">
                        <div className="container" data-aos="fade-down">
                            <div className="row pb-5">
                                <div className="col text-center">
                                    <h1 className="pb-5"><FormattedHTMLMessage id="home.mainTitle" /></h1>
                                </div>
                            </div>
                            <div className="row">
                                <div
                                    className="col-12 col-md-4 text-center aos-init aos-animate"
                                // data-aos="fade-up"
                                >
                                    <div className="icon  mb-3">
                                        <Img
                                            fixed={
                                                this.props.data.design
                                                    .childImageSharp.fixed
                                            }
                                            className=""
                                            alt=""
                                        />
                                    </div>
                                    <h3>
                                        <FormattedHTMLMessage id="home.design.title" />
                                    </h3>
                                    <p className="mb-6 mb-md-0 text-justify">
                                        <FormattedHTMLMessage id="home.design.text" />
                                    </p>
                                </div>
                                <div
                                    className="col-12 col-md-4 text-center aos-init aos-animate"
                                // data-aos="fade-up"
                                // data-aos-delay="50"
                                >
                                    <div className="icon  mb-3">
                                        <Img
                                            fixed={
                                                this.props.data.automat
                                                    .childImageSharp.fixed
                                            }
                                            className=""
                                            alt=""
                                        />
                                    </div>
                                    <h3>
                                        <FormattedHTMLMessage id="home.automatization.title" />
                                    </h3>
                                    <p className="mb-6 mb-md-0 text-justify">
                                        <FormattedHTMLMessage id="home.automatization.text" />
                                    </p>
                                </div>
                                <div
                                    className="col-12 col-md-4 text-center aos-init aos-animate"
                                // data-aos="fade-up"
                                // data-aos-delay="100"
                                >
                                    <div className="icon  mb-3">
                                        <Img
                                            fixed={
                                                this.props.data.servis
                                                    .childImageSharp.fixed
                                            }
                                            className=""
                                            alt=""
                                        />
                                    </div>
                                    <h3>
                                        <FormattedHTMLMessage id="home.service.title" />
                                    </h3>
                                    <p className="mb-0 text-justify">
                                        <FormattedHTMLMessage id="home.service.text" />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="about" className="page-section font-bigger bg-red">
                        <div className="container" data-aos="fade-down">
                            <div className="row justify-content-center section-header">
                                <div className="col text-center">
                                    <h1 className="text-white mt-0">
                                        <FormattedHTMLMessage id="home.about.title" />
                                    </h1>
                                    <hr className="divider divider-white  my-4" />
                                    <p className="text-white mb-4 text-justify">
                                        <FormattedHTMLMessage id="home.about.text1" />
                                    </p>
                                    <p className="text-white mb-4 text-justify">
                                        <FormattedHTMLMessage id="home.about.text2" />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Products intl={intl} />
                </main>
            </React.Fragment>
        )
    }
}

IndexPage.propTypes = {
    intl: intlShape.isRequired,
}

export default compose(withPageContext, withLayout, injectIntl)(IndexPage)
