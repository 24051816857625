import React from 'react'
import { FormattedHTMLMessage } from 'react-intl'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Link from '../LocalizedLink'

import './Products.sass'

const Products = ({intl}) => {
    const data = useStaticQuery(graphql`
        query {
            odhlucnovaciKryt: file(relativePath: { eq: "products/odhlucnovaci_kryt.png" }) {
                childImageSharp {
                    fixed(height: 328) {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(maxWidth: 700, maxHeight: 500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            rizeniRegulace: file(relativePath: { eq: "products/rizeni_regulace_trans.png" }) {
                childImageSharp {
                    fixed(height: 328) {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(maxWidth: 700, maxHeight: 500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            linearniPohonTrans: file(relativePath: { eq: "products/linearni_pohon_trans.png" }) {
                childImageSharp {
                    fixed(height: 328) {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(maxWidth: 700, maxHeight: 500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            nasypkaTrans: file(relativePath: { eq: "products/nasypka_trans.png" }) {
                childImageSharp {
                    fixed(height: 328) {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(maxWidth: 700, maxHeight: 500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            dopravnikTrans: file(relativePath: { eq: "products/dopravnik_trans.png" }) {
                childImageSharp {
                    fixed(height: 328) {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(maxWidth: 700, maxHeight: 500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            
            noPhoto: file(relativePath: { eq: "products/no-photo-available_trans.png" }) {
                childImageSharp {
                    fixed(height: 328) {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(maxWidth: 700, maxHeight: 500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    return (
        <section className="mt-5 container">
            <div className="row justify-content-center section-header">
                <div className="text-center">
                    <h1><FormattedHTMLMessage id="products.title" /></h1>
                    <hr className="divider light my-4" />
                </div>
            </div>

            <div className="mb-5">
                <div className="row mt-5" data-aos="fade-down">

                    {/* Kruhove podavace */}
                    <div className="col-lg-4">
                        <Link to="/products#kruhove-podavace" activeClassName="active">
                            <div className="product-item">
                                <div className="product-img">
                                    <div className="product-hover">
                                        <div className="product-hover-content">
                                            <i className="fas fa-search fa-3x"></i>
                                        </div>
                                    </div>
                                    <Img
                                        fluid={data.nasypkaTrans.childImageSharp.fluid}
                                        objectFit="cover"
                                        style={{ minHeight: '328px' }}
                                        className=""
                                        alt=""
                                    />
                                </div>
                                <div className="product-content">
                                    <h5 className="text-center">
                                        <FormattedHTMLMessage id="products.prod1" />
                                    </h5>
                                </div>
                            </div>
                        </Link>
                    </div>

                    {/* Linearni podavace */}
                    <div className="col-lg-4">
                        <Link to="/products#linearni-podavace" activeClassName="active">
                            <div className="product-item">
                                <div className="product-img">
                                    <div className="product-hover">
                                        <div className="product-hover-content">
                                            <i className="fas fa-search fa-3x"></i>
                                        </div>
                                    </div>
                                    <Img
                                        fluid={data.linearniPohonTrans.childImageSharp.fluid}
                                        objectFit="cover"
                                        style={{ minHeight: '328px' }}
                                        className=""
                                        alt=""
                                    />
                                </div>
                                <div className="product-content">
                                    <h5 className="text-center">
                                    <FormattedHTMLMessage id="products.prod2" />
                                    </h5>
                                </div>
                            </div>
                        </Link>
                    </div>

                    {/* Predzasobniky */}
                    <div className="col-lg-4">
                        <Link to="/products#predzasobniky" activeClassName="active">
                            <div className="product-item">
                                <div className="product-img">
                                    <div className="product-hover">
                                        <div className="product-hover-content">
                                            <i className="fas fa-search fa-3x"></i>
                                        </div>
                                    </div>
                                    <Img
                                        fluid={
                                            data.noPhoto.childImageSharp.fluid
                                        }
                                        objectFit="cover"
                                        style={{ minHeight: '328px' }}
                                        className=""
                                        alt=""
                                    />
                                </div>
                                <div className="product-content">
                                    <h5 className="text-center"><FormattedHTMLMessage id="products.prod3" /></h5>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                
                {/* Odhlučňovací kryty */}
                <div className="row mt-5" data-aos="fade-down">
                    <div className="col-lg-4">
                        <Link to="/products#odhlucnovaci-kryty" activeClassName="active">
                            <div className="product-item">
                                <div className="product-img">
                                    <div className="product-hover">
                                        <div className="product-hover-content">
                                            <i className="fas fa-search fa-3x"></i>
                                        </div>
                                    </div>
                                    <Img
                                        fluid={
                                            data.odhlucnovaciKryt.childImageSharp.fluid
                                        }
                                        objectFit="cover"
                                        style={{ minHeight: '328px' }}
                                        className=""
                                        alt=""
                                    />
                                </div>
                                <div className="product-content">
                                    <h5 className="text-center">
                                    <FormattedHTMLMessage id="products.prod4" />
                                    </h5>
                                </div>
                            </div>
                        </Link>
                    </div>
                    
                    {/* Řízení a regulace */}
                    <div className="col-lg-4">
                        <Link to="/products#rizeni-a-regulace" activeClassName="active">
                            <div className="product-item">
                                <div className="product-img">
                                    <div className="product-hover">
                                        <div className="product-hover-content">
                                            <i className="fas fa-search fa-3x"></i>
                                        </div>
                                    </div>
                                    <Img
                                        fluid={
                                            data.rizeniRegulace.childImageSharp.fluid
                                        }
                                        objectFit="cover"
                                        style={{ minHeight: '328px' }}
                                        className=""
                                        alt=""
                                    />
                                </div>
                                <div className="product-content">
                                    <h5 className="text-center">
                                    <FormattedHTMLMessage id="products.prod5" />
                                    </h5>
                                </div>
                            </div>
                        </Link>
                    </div>

                    {/* Doplňující prvky */}
                    <div className="col-lg-4">
                        <Link to="/products#doplnujici-prvky" activeClassName="active">
                            <div className="product-item">
                                <div className="product-img">
                                    <div className="product-hover">
                                        <div className="product-hover-content">
                                            <i className="fas fa-search fa-3x"></i>
                                        </div>
                                    </div>
                                    <Img
                                        fluid={
                                            data.dopravnikTrans.childImageSharp.fluid
                                        }
                                        objectFit="cover"
                                        style={{ minHeight: '328px' }}
                                        className=""
                                        alt=""
                                    />
                                </div>
                                <div className="product-content">
                                    <h5 className="text-center">
                                    <FormattedHTMLMessage id="products.prod6" />
                                    </h5>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Products
